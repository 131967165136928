import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404 : Page introuvable" />
    <div className="text-center mt-32">
      <h1 className="h1 text-7xl">404 : Page introuvable</h1>
      <p className="text-xl mb-16">La page que vous cherchez n'existe pas</p>
      <Link to="/" className="btn">
        Retour à l'accueil
      </Link>
    </div>
  </Layout>
)

export default NotFoundPage
